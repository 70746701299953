#splash {
  z-index: 1;
  position: fixed;
  top: calc(50% - 5.5rem);
  left: calc(50% - 5rem);
}

.splash-logo {
  width: 10rem;
}
