@use "@angular/material" as mat;

// Adjust default font size based on screen width
* {
  font-size: 16px;
  letter-spacing: -0.4px;

  // Remove 1px border from body
  --border: none !important;

  // Used for markdown logic to force innerHTML to indirectly support font-size
  big {
    font-size: 1rem !important;
    vertical-align: top;
  }

  sup {
    font-size: 0.9rem !important;
    font-weight: normal !important;
    top: 10px !important;
    vertical-align: top;
  }

  small {
    font-size: 0.75rem !important;
    vertical-align: inherit !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: linear-gradient(var(--ion-color-primary-dark), var(--ion-color-primary));
}

ul {
  color: var(--ion-color-dark);
}

// IONIC COMPONENT THEMES

// Make the hidden attribute work in Ionic 7+
[hidden] {
  display: none !important;
}

ion-content {
  --background: var(--ion-color-background);
}

ion-back-button {
  --color: var(--ion-color-always-white);
}

ion-button {
  text-transform: none;
  border-radius: 0.625rem;
  min-height: unset !important; // Override default
}

// Remove the height-width constraints for icon-only buttons
ion-button.button-has-icon-only {
  min-width: 0 !important;
  min-height: 0 !important;
}

ion-card {
  background: var(--ion-color-white);
  margin: 0.5rem;
  padding: 0;
  border-radius: 0.625rem;
  overflow: hidden;
}

ion-card-content {
  padding: 0.5rem 1rem !important;
}

ion-card-header {
  font-size: 1.1rem;
  font-weight: bold;
  --color: var(--ion-color-secondary-contrast);
  --background: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem;
}

ion-chip {
  min-height: unset; // Override 2rem default
}

ion-icon {
  color: var(--ion-color-dark);
}

ion-input {
  // min-height is set for inputs with stacked labels; use "min-height: unset !important;" to override
  min-height: 66.5px !important;
  color: var(--ion-color-dark) !important;
  --highlight-color-focused: var(--ion-color-dark) !important;
  --highlight-color-valid: var(--ion-color-dark) !important;
  --highlight-color-invalid: var(--ion-color-dark) !important;
}

// Hide spinner from generic input controls
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --color: var(--ion-color-white);
  --ion-background-color: var(--ion-color-white);
  --background-focused: var(--ion-color-white);
  --ion-item-border-color: var(--ion-color-gray-50);

  .ion-valid {
    --highlight-color-valid: var(--ion-color-dark) !important;
  }
}

ion-label {
  color: var(--ion-color-dark) !important;
  white-space: normal !important;
}

// Standardize size and width of stacked labels
.label-stacked.sc-ion-label-md-h {
  font-size: 0.8rem !important;
  -webkit-transform: translateY(30%) !important;
  transform: translateY(30%) !important;
}

ion-list {
  background: var(--ion-color-white);
}

ion-loading.loading-message {
  .loading-wrapper {
    border-radius: 0.5rem !important;
  }
}

ion-modal {
  --max-height: 90% !important;
  --width: 34rem !important;
  --max-width: 90% !important;
  --border-radius: 1rem;
}

.popover-on-top {
  z-index: 99999 !important;
}

ion-progress-bar {
  --background: var(--ion-color-gray-20);
  --progress-background: var(--ion-color-gray-80);
}

ion-radio {
  color: var(--ion-color-dark) !important;
  --color-checked: var(--ion-color-dark);
}

ion-searchbar {
  padding: 0 !important;
  --box-shadow: none !important;
}

ion-searchbar .searchbar-input-container .searchbar-input {
  font-size: 1rem;
  height: 36px !important;
  --border-radius: 6px;
  --placeholder-color: var(--ion-color-always-dark);
  --background: var(--ion-color-always-light);
  padding-inline-start: 3rem !important;
}

ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md {
  top: 7px;
}

ion-searchbar .searchbar-clear-icon {
  color: var(--ion-color-always-dark);
}

ion-segment {
  --background: var(--ion-color-segment);
}

ion-segment-button {
  min-width: 0;
  color: var(--ion-color-always-black);
  --indicator-color: var(--ion-color-always-white);
  --background: var(--ion-color-segment);
  margin: 3px 1px;

  ion-label {
    color: var(--ion-color-always-black) !important;
  }
}

ion-select {
  color: var(--ion-color-dark);
  height: 4rem !important;
}

ion-select::part(icon) {
  color: var(--ion-color-dark);
  right: 0.5rem;
  top: 0.75rem;
}

ion-tab-bar {
  --background: var(--ion-color-primary);
  --border: 0;
}

ion-tab-button {
  --color: var(--ion-color-primary);
  --color-selected: var(--ion-color-primary-dark);
}

ion-textarea {
  color: var(--ion-color-dark) !important;
  --highlight-color-focused: var(--ion-color-dark) !important;
  --highlight-color-valid: var(--ion-color-dark) !important;
  --highlight-color-invalid: var(--ion-color-dark) !important;
}

ion-title {
  color: var(--ion-color-primary-contrast);
  padding-inline: 1rem;
  font-size: 1.3rem;
}

ion-toggle {
  padding-left: 1rem;
  padding-top: 4px;
  --track-background-checked: var(--ion-color-light-green);
  --handle-background-checked: var(--ion-color-green);
  --track-background: var(--ion-color-negative-light);
}

ion-toolbar {
  --color: var(--ion-color-primary-contrast);
}

// CUSTOM COLORS

.ion-color-neutral {
  --ion-color-base: var(--ion-color-neutral) !important;
}

// SWEETALERT2

.custom-loader {
  border: 0.5rem solid var(--ion-color-primary);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.custom-left-justify {
  text-align: left !important;
}

.swal2-modal {
  $icon-size: 0.65rem;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--ion-color-always-white) !important;
  border: 3px solid var(--ion-color-accent) !important;
  border-radius: 1rem !important;

  .swal2-title {
    font-size: 1.3rem !important;
  }

  // Shrink built-in animated icons

  .swal2-icon {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line-tip"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-circular-line-left"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line-long"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-ring"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-fix"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-circular-line-right"] {
    font-size: $icon-size !important;
  }
}

.swal2-container {
  width: auto !important;
  background: rgba(255, 255, 255, 80%);
}

.swal2-loading {
  max-width: 18rem !important;
}

.swal2-html-container b {
  font-size: 18px !important;
}

.swal2-styled.swal2-cancel {
  height: 2.4rem;
  padding: 0.6375rem 1rem !important;
  color: var(--ion-color-accent) !important;
  background-color: var(--ion-color-always-white) !important;
  border-width: 2px !important;
  border-color: var(--ion-color-accent) !important;
  border-style: solid !important;
}

// KEYFRAMES - Required to be stored globally in Angular 15

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(117, 218, 173, 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0%);
  }
}

@keyframes fade {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

// ANGULAR MATERIAL THEMES

@include mat.core();
@include mat.elevation-classes();
@include mat.app-background();

:root {
  --shared-font-family: "Open Sans", sans-serif;
  --shared-tracking: -0.4px;

  --mat-sys-body-large-font: var(--shared-font-family);
  --mat-sys-body-medium-font: var(--shared-font-family);
  --mat-sys-body-small-font: var(--shared-font-family);

  --mat-app-body-large-font: var(--shared-font-family);
  --mat-app-body-medium-font: var(--shared-font-family);
  --mat-app-body-small-font: var(--shared-font-family);

  --mat-sys-title-large-font: var(--shared-font-family);
  --mat-sys-title-medium-font: var(--shared-font-family);
  --mat-sys-title-small-font: var(--shared-font-family);

  --mat-app-title-large-font: var(--shared-font-family);
  --mat-app-title-medium-font: var(--shared-font-family);
  --mat-app-title-small-font: var(--shared-font-family);

  --mat-sys-body-large-tracking: var(--shared-tracking);
  --mat-sys-body-medium-tracking: var(--shared-tracking);
  --mat-sys-body-small-tracking: var(--shared-tracking);

  --mat-app-body-large-tracking: var(--shared-tracking);
  --mat-app-body-medium-tracking: var(--shared-tracking);
  --mat-app-body-small-tracking: var(--shared-tracking);

  --mdc-theme-primary: var(--ion-color-primary);
  --mdc-theme-secondary: var(--ion-color-secondary);
  --mdc-theme-accent: var(--ion-color-accent);

  --mat-datepicker-calendar-header-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--ion-color-gray-50);
  --mat-datepicker-calendar-period-button-icon-color: var(--ion-color-dark);

  --mat-expansion-header-expanded-state-height: auto;
  --mat-expansion-header-indicator-color: var(--ion-color-dark);

  --mdc-icon-button-disabled-icon-color: var(--ion-color-gray-50);

  --mat-menu-item-leading-spacing: 0 !important;
  --mat-menu-item-trailing-spacing: 0 !important;

  --mat-select-enabled-trigger-text-color: var(--ion-color-dark);
  --mat-select-disabled-trigger-text-color: var(--ion-color-gray-50);
}

// Seems redundant, but is required to switch themes
body.dark {
  --mat-datepicker-calendar-header-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--ion-color-gray-50);
  --mat-datepicker-calendar-period-button-icon-color: var(--ion-color-dark);

  --mat-expansion-header-indicator-color: var(--ion-color-dark);

  --mdc-icon-button-disabled-icon-color: var(--ion-color-gray-50);

  --mat-select-enabled-trigger-text-color: var(--ion-color-dark);
  --mat-select-disabled-trigger-text-color: var(--ion-color-gray-50);
}

// ANGULAR MATERIAL OVERRIDES

// CHECKBOX

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  color: var(--ion-color-accent-contrast) !important;
  background-color: var(--ion-color-accent) !important;
  border-color: var(--ion-color-accent) !important;
}

// CHIPS

// Adjust size of standard chips
.mat-mdc-chip.mat-mdc-chip-option {
  height: 32px;
  margin: 4px !important;
}

// Adjust default font size for chip text
.mat-mdc-chip-action-label {
  font-size: 0.875rem !important;
}

// Change the background color for the selected/unselected chips
.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-selected {
  color: transparent !important;
  background: linear-gradient(var(--ion-color-chip-selected-light), var(--ion-color-chip-selected));

  .mat-mdc-chip-action-label {
    color: var(--ion-color-chip-selected-contrast) !important;
  }
}

.mat-mdc-chip.mat-mdc-chip-option.mat-primary:not(.mat-mdc-chip-selected) {
  color: transparent !important;
  background: linear-gradient(var(--ion-color-chip-light), var(--ion-color-chip));

  .mat-mdc-chip-action-label {
    color: var(--ion-color-chip-contrast) !important;
  }
}

.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-disabled {
  background: var(--ion-color-chip-disabled) !important;
  border: 1px solid var(--ion-color-chip-disabled-light) !important;

  .mat-mdc-chip-action-label {
    color: var(--ion-color-chip-disabled-contrast) !important;
  }
}

// Hide checkmark from selected chip(s)
.mat-mdc-chip-graphic {
  width: 0 !important;
}

.mdc-evolution-chip__checkmark {
  display: none !important;
}

// Make chips rounded
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
}

// DATEPICKER

.mat-calendar-body-cell-content {
  border-width: 0 !important;
}

.mat-calendar-body-selected {
  color: var(--ion-color-accent-contrast) !important;
  background-color: var(--ion-color-accent) !important;
}

.mat-calendar-controls {
  margin-top: 0 !important;
}

// EXPANSION

.mat-expansion-indicator svg {
  color: var(--ion-color-dark);
  width: 2.2rem !important;
  height: 3rem !important;
  margin-top: 4px !important;
}

.mat-mdc-expansion-indicator::after {
  margin-top: 1.75rem !important;
  padding: 6px !important;
  transform-origin: 180% 50%;
  color: var(--ion-color-always-black);
}

.mat-accordion .mat-expansion-panel {
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.mat-expansion-panel .mat-expansion-panel-header {
  height: auto;
  padding: 0.5rem 1rem;
  background: var(--ion-color-white) !important;
  border-radius: 0.5rem 0 0 0;
  display: flex;
  align-items: flex-start;
}

.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  background: var(--ion-color-white) !important;
}

// FORM FIELD

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

// INPUT

// MENU

.mat-mdc-menu-item-text {
  color: var(--ion-color-dark);
}

.mat-mdc-menu-panel {
  background-color: var(--ion-color-background) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem !important;

  ion-icon {
    margin-right: 0.5rem;
  }
}

// Hide checkmark from dropdown lists
.mat-mdc-menu-panel .mat-pseudo-checkbox {
  display: none;
}

// Meny panel overrides must be defined globally
.short-menu {
  height: 14.5rem !important; // Show 5.5 rows at 2.5rem each (plus margins)
}

// PAGINATOR

.mat-mdc-paginator {
  background-color: var(--ion-color-white) !important;

  .mat-mdc-paginator-container {
    min-height: 40px !important;
  }
}

// RADIO

// SELECT

// Force select panel to the width of the drop-down list or to fit the content, whichever is wider
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mdc-line-ripple {
  color: var(--ion-color-gray-50) !important; // Underline color
}

.mdc-list-item__primary-text {
  width: 100%;
}

mat-select-trigger,
.mat-mdc-select-value-text,
.mat-mdc-select-min-line,
.mdc-list-item__primary-text {
  font-size: 0.9rem !important;
}

.mat-mdc-select-panel {
  background-color: var(--ion-color-background) !important; // Drop-down background color
  max-height: 50vh !important;
}

// Hide checkmark from dropdown lists
.mat-mdc-select-panel .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-option {
  color: var(--ion-color-dark) !important;
}

.mdc-text-field {
  font-size: 0.875rem !important;
  padding: 0 !important;
}

// Set height of dropdown list (defaults to 56px)
.mdc-text-field--filled {
  height: 2rem !important;
}

.mat-mdc-form-field-infix {
  padding: 0.25rem 0 0 0 !important;
}

// SORT

.mat-sort-header-arrow {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

// TABLE

.mat-mdc-table {
  background-color: transparent !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  padding: 0 !important;
  color: var(--ion-color-dark);
}

// TOOLTIPS

.mat-mdc-tooltip {
  white-space: pre-line;
  max-width: 15rem;
}

.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
      color: var(--ion-color-white);
      background-color: rgba(var(--ion-color-dark-rgb), 0.8);
      font-size: 0.8rem;
    }
  }
}
