@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v40-latin-regular.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v40-latin-italic.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v40-latin-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v40-latin-700italic.woff2") format("woff2");
}
